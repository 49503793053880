//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from "../flight-hotel-train";
import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'
import moment from 'moment';
import store from '@/lib/store';

//int32审批类型 1-酒店退 2-机票退 3火车票退 4机票改 5火车票改

export default {
    name: "ApplyDetail",
    data() {
        return {
            subsidyControlRule: 0,//预算管控 0：不开启 1：开启（只允许按出差单申请金额内预订行程） 默认：0
            detail: {}
        };
    },
    props: {
        detailData: Object,
        activities: Array,
        activitiesNum: Number,
    },
    async mounted() {
      await this.$store.state.policyDefer.promise;
      this.subsidyControlRule = store.state.policy.subsidyControlRule;
    },
    methods: {
        clickOrderDetailsChange(){
            let item = this.detail.changeDetailResponse.changeDetail.oldSegmentList[0];
            this.$router.push({
                name: 'admin-my-order-flight-change-detail',
                query: {
                    orderNo: item.chaOrderNo,
                    psgNoList: item.psgNo,
                    oriOrderNo: this.detail.orderNo,
                }
            })
        },
        clickOrderDetails(){
            this.$router.push({
                name: 'admin-my-order-flight-detail',
                query: {
                    orderNo: this.detail.orderNo,
                }
            })
        },
        getWeek (date) { // 参数时间戳
            switch (date) {
                case 1:
                    return '周一'
                case 2:
                    return '周二'
                case 3:
                    return '周三'
                case 4:
                    return '周四'
                case 5:
                    return '周五'
                case 6:
                    return '周六'
                case 0:
                    return '周日'
            }
        },

        /*getWeek(data) {
            switch (data) {
                case 0:
                    return '星期日';
                case 1:
                    return '星期一';
                case 2:
                    return '星期二';
                case 3:
                    return '星期三';
                case 4:
                    return '星期四';
                case 5:
                    return '星期五';
                case 6:
                    return '星期六';
            }
        },*/
        datedifference(sDate1, sDate2) {
            var dateSpan,
                tempDate,
                iDays,
                sDate1 = Date.parse(sDate1);
            sDate2 = Date.parse(sDate2);
            dateSpan = sDate2 - sDate1;
            dateSpan = Math.abs(dateSpan);
            iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
            return iDays;
        },
        lookUrl(url) {
            window.open(url);
        }
    },
    components: {
        List,
        TrainTimetable
    },
    created() {
        let evectionNo = this.$route.query.evectionNo;
        if (evectionNo) {
            this.detail.evection = this.detailData;
            this.detail.staffDetailVos = this.detailData.travellerList;
        } else {
            this.detail = this.detailData;
            if (this.detail.type == 2 && this.detail.reasonRefund.url) {
                let urlName = this.detail.reasonRefund.url.split('/')[this.detail.reasonRefund.url.split('/').length - 1];
                this.detail.reasonRefund.urlName = `${urlName}`.length > 13 ? `${urlName.slice(0,12)}...` : `${urlName}`;
            }
            if (this.detail.type == 4 && this.detail.reasonChange.url) {
                let urlName = this.detail.reasonChange.url.split('/')[this.detail.reasonChange.url.split('/').length - 1];
                this.detail.reasonChange.urlName = `${urlName}`.length > 13 ? `${urlName.slice(0,12)}...` : `${urlName}`;
            }
        }



        //退票、改签火车票时刻表init
        this.$nextTick(() => {
            if ([3].indexOf(this.detail.type) != -1) {
                let params = {
                    fromStationCode: this.detail.trainsOrderReturnDetailResponse.passengers[0].fromStationCode,
                    toStationCode: this.detail.trainsOrderReturnDetailResponse.passengers[0].toStationCode,
                    trainCode: this.detail.trainsOrderReturnDetailResponse.passengers[0].trainCode,
                    trainDate: this.detail.trainsOrderReturnDetailResponse.startDate,
                };
                this.$refs.aTrainTimetable.init(params);
            }
            if ([5].indexOf(this.detail.type) != -1 && this.detail.trainsOrderChangeDetailResponse) {
                let paramsB = {
                    fromStationCode: this.detail.trainsOrderChangeDetailResponse.changeFromStationCode,
                    toStationCode: this.detail.trainsOrderChangeDetailResponse.changeToStationCode,
                    trainCode: this.detail.trainsOrderChangeDetailResponse.changeTrainCode,
                    trainDate: this.detail.trainsOrderChangeDetailResponse.changeStartDate,
                };
                this.$refs.bTrainTimetable.init(paramsB);
                let paramsC = {
                    fromStationCode: this.detail.trainsOrderChangeDetailResponse.originalChangeFromStationName,
                    toStationCode: this.detail.trainsOrderChangeDetailResponse.originalChangeToStationName,
                    trainCode: this.detail.trainsOrderChangeDetailResponse.originalTrainCode,
                    trainDate: moment(this.detail.trainsOrderChangeDetailResponse.originalChangeStartDate).format('YYYY-MM-DD'),
                };
                this.$refs.cTrainTimetable.init(paramsC);
            }

        });

    },
    filters: {
        carPolicyTypeText(val) {
            let valText = '';
            switch (val) {
                case 1:
                    valText = '出差用车';
                    break;
                case 2:
                    valText = '加班用车';
                    break;
                case 3:
                    valText = '接送服务';
                    break;
                case 4:
                    valText = '代叫用车';
                    break;
                case 5:
                    valText = '定点通勤';
                    break;
                case 6:
                    valText = '外勤用车';
                    break;
            }
            return valText
        },
        format_applyType(val) {
            switch (Number(val)) {
                case 1:
                    return '出差申请';
                    break;
                case 2:
                    return '超标申请';
                    break;
                case 3:
                    return '报销申请';
                    break;
                case 4:
                    return '改签申请';
                    break;
                case 5:
                    return '退订申请';
                    break;
            }
        },
    },
    activated() {
    },
    watch: {

    },
    computed: {
        activitiesSrc(){
            if (this.$store.state.workflow === '2') {
                let srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/loseEfficacy.png';
                if (this.activitiesNum > 0) {
                    srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/pending.png';
                } else if(this.activitiesNum == 0) {
                    if (this.activities[this.activities.length - 1].timestamp == '审批同意') {
                        srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/refuse.png';
                    } else {
                        srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/agreeWith.png';
                    }
                }
                return srcBox;
            } else {
                let srcBox = '';
                console.log(this.activities)
                if (this.activities.length === 0) {
                    return srcBox;
                }
                switch (this.activities[this.activities.length - 1].status) {
                    case 1:
                        srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/pending.png';
                        break;
                    case 2:
                        srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/pending.png';
                        break;
                    case 3:
                        srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/loseEfficacy.png';
                        break;
                    case 4:
                        srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/loseEfficacy.png';
                        break;
                    case 5:
                        srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/agreeWith.png';
                        break;
                    case 6:
                        srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/pending.png';
                        break;
                    default:
                        srcBox = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/09/pending.png';
                }
                return srcBox;
            }
        },
    }
};
