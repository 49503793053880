
// 【576】获取预算险类种类
const __request = require(`./__request/__request_contentType_json`);
const consumer_budget_getBudgetInsurance = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/budget/getBudgetInsurance',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_budget_getBudgetInsurance;