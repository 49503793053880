//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from "./flight-hotel-train";
import moment from "moment";
export default {
  name: "ApplyDetail",
  data() {
    return {
      detail: {},
      approveType: null,
      intervalFun: null,
    };
  },
  props: {
    detailData: Object
  },
  methods: {
    //改签火车票详情
    toTypeServeTrainChange(val){
      this.intervalFunInterval();
      this.$router.push({
        name: "admin-my-order-train-change-detail",
        query: {
          orderNo: val.changeNo,
        },
      });
    },
    //改签机票详情
    toTypeServeFlightChange(val,oriOrderNo){
      this.intervalFunInterval();
      this.$router.push({
        name: 'admin-my-order-flight-change-detail',
        query: {
          orderNo: val.chaOrderNo,
          psgNoList: val.psgNo,
          oriOrderNo
        }
      })
    },
    //退订火车票详情
    toTypeServeTrainRefund(val){
      this.intervalFunInterval();
      this.$router.push({
        name: 'admin-my-order-train-refund-detail',
        query: {
          refundNo: val.refundNo
        }
      })
    },
    //退订机票详情
    toTypeServeFlightRespone(val){
      this.intervalFunInterval();
      this.$router.push({
        name: 'admin-my-order-flight-refund-detail',
        query: {
          orderNo: val.refOrderNo,
        }
      })
    },
    //退订酒店详情
    toTypeServeHotel(val){
      this.intervalFunInterval();
      this.$router.push({
        name: 'admin-hotel-refund-detail',
        query: {
          orderNo: val.refOrderNo,
        }
      })
    },
    //订单详情--orderNo
    orderNoClick(val,type){
      if (type == 1) {
        //机票订单详情
        let orderNo = val.orderNo;
        this.intervalFunInterval();
        this.$router.push({
          name: "admin-my-order-flight-detail",
          query: {
            orderNo,
          },
        });
      }
      if (type == 2) {
        //火车票订单详情
        let orderNo = val.orderNo;
        this.intervalFunInterval();
        this.$router.push({
          name: "admin-my-order-train-detail",
          query: {
            orderNo,
          },
        });
      }
      if (type == 3) {
        //酒店订单详情
        let orderNo = val.orderNo;
        this.intervalFunInterval();
        this.$router.push({
          name: "admin-hotel-order-detail",
          query: {
            orderNo,
          },
        });
      }
    },
    datedifference(sDate1, sDate2) {
      var dateSpan,
        tempDate,
        iDays,
        sDate1 = Date.parse(sDate1);
      sDate2 = Date.parse(sDate2);
      dateSpan = sDate2 - sDate1;
      dateSpan = Math.abs(dateSpan);
      iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
      return iDays;
    },
    lookUrl(url) {
      window.open(url);
    },
    intervalFunInterval(){
      if (this.intervalFun) {
        clearInterval(this.intervalFun);
      }
    },
    // 超时处理
    cancelOrder(){
      this.intervalFunInterval();
      this.$router.go();
    },
    setPayIntervalFlight() {
      this.intervalFun = setInterval(() => {
        this.detail.flightDetail.milliseconds--;
        if (this.detail.flightDetail.milliseconds <= 0) {
          clearInterval(this.intervalFun);
          this.cancelOrder()
        }
      }, 1000)
    },
    setPayIntervalTrain() {
      this.intervalFun = setInterval(() => {
        this.detail.trainDetail.milliseconds--;
        if (this.detail.trainDetail.milliseconds <= 0) {
          clearInterval(this.intervalFun);
          this.cancelOrder()
        }
      }, 1000)
    },
    setPayIntervalHotel() {
      this.intervalFun = setInterval(() => {
        this.detail.hotelDetail.milliseconds--;
        if (this.detail.hotelDetail.milliseconds <= 0) {
          clearInterval(this.intervalFun);
          this.cancelOrder()
        }
      }, 1000)
    },
  },
  components: {
    List
  },
  created() {
    this.approveType = this.$route.query.approveType;
    let evectionNo = this.$route.query.evectionNo;
    if (evectionNo) {
      this.detail.evection = this.detailData;
      this.detail.staffDetailVos = this.detailData.travellerList;
    } else {
      this.detail = this.detailData;
      if (this.approveType === 7) {
        if (this.detail.flightDetail != null) {
          if (this.detail.flightDetail.milliseconds > 0) {
            this.setPayIntervalFlight()
          }
        }
        if (this.detail.trainDetail != null) {
          if (this.detail.trainDetail.milliseconds > 0) {
            this.setPayIntervalTrain()
          }
        }
        if (this.detail.hotelDetail != null) {
          if (this.detail.hotelDetail.milliseconds > 0) {
            this.setPayIntervalHotel()
          }
        }
      }
    }
  },
  filters: {
    filterIntervalTime(val) {
      if (val) {
        // 转换为式分秒
        let h = parseInt(val / 60 / 60 % 24);
        h = h < 10 ? '0' + h : h;
        let m = parseInt(val / 60 % 60);
        m = m < 10 ? '0' + m : m;
        let s = parseInt(val % 60);
        s = s < 10 ? '0' + s : s;
        let text = `${m}分${s}秒`;
        if (h !== '00') {
          text = h + '时' + text
        }
        return text
      } else {
        return '00分00秒'
      }
    },
    maxTestDetail(text){
      let textBox = '';
      if (text.length > 6) {
        textBox = text.slice(0,5) + '...'
      } else {
        textBox = text
      }

      return textBox
    },
    filterWeek(val) {
      if (val === '') {
        return ''
      }
      let day = moment(val).day();
      if (day === 0) {
        return '星期日'
      } else if (day === 1) {
        return '星期一'
      } else if (day === 2) {
        return '星期二'
      } else if (day === 3) {
        return '星期三'
      } else if (day === 4) {
        return '星期四'
      } else if (day === 5) {
        return '星期五'
      } else if (day === 6) {
        return '星期六'
      } else {
        return ''
      }
    },
    format_applyType (val) {
      switch (Number(val)) {
        case 1:
          return '出差申请';
          break;
        case 2:
          return '超标申请';
          break;
        case 3:
          return '报销申请';
          break;
        case 4:
          return '改签申请';
          break;
        case 5:
          return '退订申请';
          break;
        case 7:
          return '预订审批';
          break;
        case 8:
          return '改签审批';
          break;
        case 9:
          return '退票审批';
          break;
      }
    },
  },
  mounted(){

  },
  activated() {},
  watch: {},
  computed: {}
};
