//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getWeekDay } from "@/lib/utils/common";
import ChangClause from "@/page/admin/travel-book/flight/component/changClause";
import stipulates from "@/lib/data-service/haolv-default/consumer_flight_search_stipulates";

export default {
  data() {
    return {
      getWeekDay: getWeekDay,
      ruleText: [],
      exceedStatu: false
    };
  },
  props: {
    detailData: Object
  },
  components: {
    ChangClause
  },
  methods: {
    backRule(flight, val) {
      console.log(val);
      this.ruleText = [];
      let data = {
        rqData: {
          arrCityPortCode: val.arrCityCode,
          cabinCode: val.cabinCode,
          depCityPortCode: val.depCityCode,
          depDate: val.depDate,
          flightNo: val.flightNo,
          flightProductType: val.flightProductType,
          flightType: flight.flightType,
          psgType: flight.psgType,
          businessExt: flight.businessExt,
        }
      };
      stipulates(data).then(res => {
        let refRule = res.rsData.refRule;
        let chaRule = res.rsData.chaRule;
        if (refRule && chaRule) {
          refRule = refRule.rules;
          chaRule = chaRule.rules;
          refRule.map(item => (item.type = "refRule"));
          chaRule.map(item => (item.type = "chaRule"));
          this.ruleText = [refRule, chaRule];
        }
        this.ruleLoading = false;
        this.luggage = res.rsData.luggage;
      });
    }
  },
  created() {
    this.$route.query.approveType == 2 ? (this.exceedStatu = true) : "";
  },
  activated() {},
  watch: {},
  computed: {}
};
