//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChangClause from "@/page/admin/travel-book/flight/component/changClause";
import stipulates from "@/lib/data-service/haolv-default/consumer_flight_search_stipulates";
import fligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
export default {
  name: "FlightClass",
  data() {
    return {
      ruleText: [],
      loading: false,
      changeShow: null,
      luggage: '',
    };
  },
  props: {
    detail: Array,
    type: String,
    ticketDetail: Object
  },
  components: {
    ChangClause
  },
  methods: {
    //退改规则
    backRule(val, index) {
      console.log('val', val)
      this.loading = true;
      this.ruleText = [];
      this.changeShow = index;
      let data = {
        cabinCode: val.cabin || val.cabinCode,
        flightNo: val.flightNo
      };
      fligthRules(data).then(res => {
        let refRule = res.datas.rsData.refRule;
        let chaRule = res.datas.rsData.chaRule;
        if (refRule && chaRule) {
          refRule = refRule.rules;
          chaRule = chaRule.rules;
          refRule.map(item => (item.type = "refRule"));
          chaRule.map(item => (item.type = "chaRule"));
          this.ruleText = [refRule, chaRule];
        }
        this.luggage = res.datas.rsData.luggage
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
    },
    noShow() {
      this.changeShow = null;
    }
  },
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
