import Q from 'q';
import { Loading } from 'element-ui';
import ApproveTimeLine from "../.././approveTimeLine";
import ApplyDetailForm from "../.././applyDetailForm";
import DialogBox from "../.././dialogBox";
import aduitEvection from "@/lib/data-service/haolv-default/consumer_apply_aduitEvection";
import { ChangeDateFormat } from "@/lib/utils/common.js";
import TicketChangeDetail from "../.././ticketChangeDetail";
import TicketRefundDetail from "../.././ticketRefundDetail";
import ExceedInfo from "../.././exceedInfo";
import FlightDetail from "@/page/admin/myOrder/component/flightDetail/index.vue";
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.5/index.vue';
import ApprovalProcessRejection from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/approval-process-rejection/1.0.0/index.vue';
import moment from "moment"

// 退票审批 5
import consumer_apply_aduitRefund from "@/lib/data-service/haolv-default/consumer_apply_aduitRefund";

// 改期审批 4
import consumer_apply_aduitChange from "@/lib/data-service/haolv-default/consumer_apply_aduitChange";

// 超标审批 2
import consumer_apply_aduitOverproof from "@/lib/data-service/haolv-default/consumer_apply_aduitOverproof";


import consumer_approveFlow_getApproveNodes from '@/lib/data-service/haolv-default/consumer_approveFlow_getApproveNodes'
import consumer_apply_aduitOverproofOnBusiness from "@/lib/data-service/haolv-default/consumer_apply_aduitOverproofOnBusiness";
import ApprovalProcessApprovalProcess from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/approval-process-approval-process/1.0.0/index.vue'
import WorkflowProcessLineForArray from "@/component/workflow-process-line-for-array";
import {formatStartNodeShow} from "@/lib/data-service/haolv-default/design";
import RollbackModal from "@/page/admin/office/my-apply/components/process/RollbackModal.vue";
import AddMultiModal from "@/page/admin/office/my-apply/components/process/AddMultiModal.vue";
import DeleteMultiModal from "@/page/admin/office/my-apply/components/process/DeleteMultiModal.vue";
import AssigneeModal from "@/page/admin/office/my-apply/components/process/AssigneeModal.vue";
import RevokeModal from "@/page/admin/office/my-apply/components/process/RevokeModal.vue";
import DelegateModal from "@/page/admin/office/my-apply/components/process/DelegateModal.vue";
import QueryMultiUserModal from "@/page/admin/office/my-apply/components/process/QueryMultiUserModal.vue";
import ResolveModal from "@/page/admin/office/my-apply/components/process/ResolveModal.vue";
import AgreeModal from "@/page/admin/office/my-apply/components/process/AgreeModal.vue";
import RefuseModal from "@/page/admin/office/my-apply/components/process/RefuseModal.vue";
export default {
  name: "LookTravel",
  data() {
    return {
      toPassApplySwitch: true,
      loading: false,
      loadingInstance: null,
      dialogShow: false,
      dialogChangeShow: false,
      activitiesNum: 0,
      evectionNo: this.$route.query.evectionNo,
      activities: [],
      staffList: [], // 有审批流的审核人全集
      staffIdList: [],
      staffRadio: 1,

      modalConfig: {
        agreeVisible: false,
        commentVisible: false,
        delegateVisible: false,
        assigneeVisible: false,
        resolveVisible: false,
        refuseVisible: false,
        rollbackVisible: false,
        addMultiVisible: false,
        queryMultiUserVisible: false,
        revokeVisible: false,
        deleteMultiVisible: false
      },
    };
  },
  components: {
    RefuseModal,
    AgreeModal,
    ResolveModal,
    QueryMultiUserModal,
    DelegateModal,
    RevokeModal,
    AssigneeModal,
    DeleteMultiModal,
    AddMultiModal,
    RollbackModal,
    WorkflowProcessLineForArray,
    ApproveTimeLine,
    DialogBox,
    ApplyDetailForm,
    TicketChangeDetail,
    TicketRefundDetail,
    ExceedInfo,
    FlightDetail,
    TravelerMultipleSelector,
    ApprovalProcessRejection,
    ApprovalProcessApprovalProcess
  },
  props: {
    type: String,
    detailData: Object,
    getDetail: Function,
    opaList: {
      // 新审批流操作权限
      type: Array,
      default: ()=>[],
    },
    processInfo: {
      // 新审批流操作表单
      type: Object,
      default: ()=>{},
    },
  },
  methods: {
    toPassApply() {
      this.loading = true;
      const approveType = Number(this.$route.query.approveType);
      const params = {
        approveType: approveType, // approveType 审批类型：1.出差申请，2.超标申请，3.报销申请，4.改签，5.退票
        applyNo: this.detailData.approveApply.applyNo, // 审批单号
        approveFlowId: this.detailData.approveApply.approveFlowId, // 审批流程流水号
        isHasFlow: this.detailData.isHasFlow, // 是否有流程
        isLastNode: this.detailData.isLastNode, // 是否流程最后一个
        flightDetail: this.detailData.flightDetail, // 机票详情
        hotelDetail: this.detailData.hotelDetail, //
        trainDetail: this.detailData.trainDetail,
      };
      if (approveType === 4 || approveType === 8) {
        // 机票详情
        params.flightDetail = this.detailData.changeDetailResponse ? this.detailData.changeDetailResponse.changeDetail : null;
        // 火车票详情
        params.trainDetail = this.detailData.trainsOrderChangeDetailResponse ? this.detailData.trainsOrderChangeDetailResponse : null;
      }
      if (approveType === 5 || approveType === 9) {
        // 机票详情
        params.flightDetail = this.detailData.refundOrdersDetailRespone ? this.detailData.refundOrdersDetailRespone.result : null;
        // 酒店详情
        params.hotelDetail = this.detailData.todHotelOrderInterimResponse ? this.detailData.todHotelOrderInterimResponse.orderInterimResult : null;
        // 火车票详情
        params.trainDetail = this.detailData.trainsOrderReturnDetailResponse ? this.detailData.trainsOrderReturnDetailResponse : null;
      }
      this.$refs.aApprovalProcessApprovalProcess.run(params)
        .then(result => {
          if (result.type === '001') {
            this.$router.push({ name: "admin-my-apply-manage-have-approve" });
          }
          this.loading = false;
        })
        .catch(rej => {
          this.loading = false;
          this.toPassApplySwitch = false;
        });
    },
    applyReject() {
      this.loading = true;
      const approveType = Number(this.$route.query.approveType);
      let params = {
        applyNo: this.detailData.approveApply.applyNo,
        approveType
      };
      this.$refs.aApprovalProcessRejection.run(params)
        .then(res => {
          this.loading = false;
          if (res.resultType) {
            //拒绝成功
            this.$router.push({
              name: 'admin-my-apply-manage-have-approve',
            })
          } else{
            //拒绝失败
          }

        })
        .catch(rej => {
          this.loading = false;
        })

    },
    getApplyList(val) {
      const approveType = Number(this.$route.query.approveType);
      this.loading = true;
      if (approveType === 4) {
        const params = {
          applyNo: this.detailData.approveApply.applyNo,
          evectionNo: "",
          approveStatus: 2,
          isOver: true,
          nextApproverId: val.length ? val[0].id : "",
          nextApproverName: val.length ? val[0].name : "",
          rejectReason: "",
        };
        consumer_apply_aduitChange(params)
            .then((res) => {
              this.$message({ type: "success", message: "审批通过！" });
              this.$router.push({ name: "admin-my-apply-manage-have-approve" });
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
      } else if (approveType === 5) {
        const params = {
          applyNo: this.detailData.approveApply.applyNo,
          evectionNo: "",
          approveStatus: 2,
          isOver: true,
          nextApproverId: val.length ? val[0].id : "",
          nextApproverName: val.length ? val[0].name : "",
          rejectReason: "",
        };

        consumer_apply_aduitRefund(params)
            .then((res) => {
              this.$message({ type: "success", message: "审批通过！" });
              this.$router.push({ name: "admin-my-apply-manage-have-approve" });
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
      } else if (approveType === 2) {
        const params = {
          applyNo: this.detailData.overproofs.applyNo,
          evectionNo: this.detailData.evection.evectionNo,
          approveStatus: 2,
          isOver: true,
          nextApproverId: val.length ? val[0].id : "",
          nextApproverName: val.length ? val[0].name : "",
          rejectReason: "",
        };

        consumer_apply_aduitOverproofOnBusiness(params)
            .then((res) => {

              this.$message({ type: "success", message: "审批通过！" });
              this.$router.push({ name: "admin-my-apply-manage-have-approve" });
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
      } else {
        let data = {
          applyNo: this.detailData.approveApply.applyNo,
          approveStatus: 2,
          isOver: val.length ? false : true,
          nextApproverId: val.length ? val[0].id : "",
          nextApproverName: val.length ? val[0].name : "",
        };
        aduitEvection(data).then((res) => {
          console.log(res);
          this.$message({ type: "success", message: "审批通过！" });
          this.$router.push({ name: "admin-my-apply-manage-have-approve" });
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
        });
      }
    },
    setShow() {
      this.dialogChangeShow = false
      if (this.staffRadio === 1) {
        // 不需要下一个
        this.getApplyList([])
      } else {
        // 需要下一个
        this.$refs.travelerMultipleSelector.show();
      }
    },
    back() {
      this.$router.go(-1)
    },
    getFlow() {
      //formatStartNodeShow({"processInstanceId": "1780042357087338496", "userId": 2024, "deptId": 205}).then(res=>{
      formatStartNodeShow({
        processInstanceId: this.$route.query.applyNo,
        userId: this.$store.state.userInfo.id,
        deptId: this.$store.state.userInfo.deptId,
        budgetAmount: this.detailData.evection.budgetAmount || this.detailData.budgetAmount || 0,
      }).then(res=>{
        let list = res.result;
        let form = {
          line: list
        }
        console.log(this.$refs.workflowProcessLineForArray);
        this.$refs.workflowProcessLineForArray.init(form);
      })
      /*approveFlow_matching({companyId: this.$store.state.userInfo.companyId, userId: this.$store.state.userInfo.id, groupId: 1}).then(res=>{
        let form = res.result;
        console.log(this);
        console.log('123', this.$refs.workflowProcessLine);
        this.$refs.workflowProcessLine.init(form);
      });*/
    },
    // 新审批
    newPass(val) {
      console.log('111222');
      switch (val.key) {
        case 'pass':
          // 通过
          this.onAgree();
          break
        case 'refuse':
          // 拒绝
          this.onRefuse();
          break
        case 'reject':
          // 驳回
          this.onRollback();
          break
        case 'frontJoin':
          // 委派
          this.onDelegateTask();
          break
        case 'backJoin':
          // 转办
          this.onAssignee();
          break
        case 'addAssignee':
          // 加签
          this.onAddMulti();
          break
        case 'delAssignee':
          // 减签
          this.onDeleteMulti();
          break
      }
    },
    handleCommand(val) {
      console.log(val);
      this.opaList.forEach(value => {
        if (value.key === val) {
          this.newPass(value);
        }
      })
    },

    onAgree() {
      this.modalConfig.agreeVisible = true;
    },
    onDelegateTask() {
      this.modalConfig.delegateVisible = true;
    },
    onResolveTask() {
      this.modalConfig.resolveVisible = true
    },
    onRefuse() {
      this.modalConfig.refuseVisible = true
    },
    onRevoke() {
      this.modalConfig.revokeVisible = true;
    },

    onAssignee() {
      this.modalConfig.assigneeVisible = true
    },
    onRollback() {
      this.modalConfig.rollbackVisible = true;
    },
    onAddMulti() {
      this.modalConfig.addMultiVisible = true;
    },
    onQueryMultiUsersInfo() {
      this.modalConfig.queryMultiUserVisible = true;
    },
    onDeleteMulti() {
      this.modalConfig.deleteMultiVisible = true;
    },
    // 添加评论
    onComments() {
      this.modalConfig.commentVisible = true;
    },

    _success() {
      this.back();
    },
  },
  created() {},
  mounted() {

  },
  activated() {},
  watch: {
    detailData(val) {
      let __this = this
      this.activitiesNum = 0;
      if (val.recordList && val.recordList.length) {
        this.activities = val.recordList.map((item) => {
          let statu = item.approveStatus; //未开始，1.待我审，2.审核通过，3.驳回，4.已失效
          if (statu === 1) this.activitiesNum++;
          let color = statu === 2 ? "#58CA18" : statu === 2 ? "#ff8c4d" : "";
          let icon = statu === 2 ? "el-icon-check" : "el-icon-more";
          let time = moment(item.gmtModified).format('YYYY-MM-DD HH:mm:ss');
          let statuText =
              statu === 1
                  ? "待审核"
                  : statu === 2
                  ? "审核通过"
                  : statu === 3
                      ? "驳回"
                      : statu === 4
                          ? "已失效"
                          : "未开始";
          return {
            content: `${item.approverUserName}  ${
                item.deptName ? item.deptName : ""
            }`,
            timestamp: statuText,
            color: color,
            icon: icon,
            name: "",
            time: time,
          };
        });

        const approveType = Number(this.$route.query.approveType);
        console.log(val);
        if (approveType === 2) {
          this.activities.unshift({
            name: `${val.evection.initialName || ''} ${val.evection.feeAffiliationName || ''}`,
            timestamp: `发起时间: ${ChangeDateFormat(
                val.recordList[0].gmtCreate
            )}`,
            color: "#58CA18 ",
            icon: "el-icon-check",
          });
        } else {
          this.activities.unshift({
            content: "",
            name: `${val.evection.initialName || ''} ${val.evection.feeAffiliationName || ''}`,
            timestamp: `发起时间:${ChangeDateFormat(
                val.recordList[0].gmtCreate
            )}`,
            color: "#58CA18 ",
            icon: "el-icon-check",
          });
        }
      }

      this.$nextTick(() => {
        console.log(this.$refs.travelerMultipleSelector) // 这个组件在页面上并没有
        if (this.$refs.travelerMultipleSelector) {
          this.$refs.travelerMultipleSelector.init_parameter.btn_ok_click_event_handler = function (args) {
            console.log('args', args)
            let selected_entity_list = args.selected_entity_list
            selected_entity_list.forEach(value => {
              value.id = value.userId
            })
            __this.getApplyList(selected_entity_list)
          };
          this.$refs.travelerMultipleSelector.init_parameter.get_data_source_parameter = function () {
            const d = {
              companyId: '',
              isSelectCustomer: false,
            };
            return Q.resolve(d);
          };
          this.$refs.travelerMultipleSelector.init();
        }

      })
    },
    loading (val) {
      if (val) {
        this.loadingInstance = Loading.service({target: 'body', text: '请求中...'});
      } else {
        this.loadingInstance.close();
      }
    },
  },
  computed: {
    showMoreOpa() {
      let moreBtnList = [];
      let moreBtn = ['reject', 'frontJoin', 'backJoin', 'addAssignee', 'delAssignee'];
      this.opaList.forEach(value => {
        if (moreBtn.indexOf(value.key) > -1 && value.checked) {
          moreBtnList.push(value);
        }
      })
      return moreBtnList;
    },
    currentProcessInfo: {
      get () {
        return this.processInfo
      },
      set (val) {
        this.$emit('update:processInfo', val)
      }
    }
  },
};
