//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from "./flight-hotel-train";
import moment from "moment";
import store from '@/lib/store';
export default {
  name: "ApplyDetail",
  data() {
    return {
      subsidyControlRule: 0,//预算管控 0：不开启 1：开启（只允许按出差单申请金额内预订行程） 默认：0
      detail: {}
    };
  },
  props: {
    detailData: Object
  },
  methods: {
    datedifference(sDate1, sDate2) {
      var dateSpan,
        tempDate,
        iDays,
        sDate1 = Date.parse(sDate1);
      sDate2 = Date.parse(sDate2);
      dateSpan = sDate2 - sDate1;
      dateSpan = Math.abs(dateSpan);
      iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
      return iDays + 1;
    },
    lookUrl(url) {
        console.log(url);
      window.open(url);
    },
      setIcon(url) {
        let urlList = url.split('.');
        let type = urlList[urlList.length - 1];
        if (['jpg','png','bmp','jpeg'].indexOf(type) > -1) {
            return 'haolv-icona-zu4793';
        }
        if (['xls', 'xlsx'].indexOf(type) > -1) {
            return 'haolv-icona-zu4794';
        }
        if (["doc", "docx"].indexOf(type) > -1) {
            return 'haolv-icona-zu4795';
        }
        return 'haolv-icona-zu4795';
      },
  },
  components: {
    List
  },
  created() {
    let evectionNo = this.$route.query.evectionNo;
    if (evectionNo) {
      this.detail.evection = this.detailData;
      this.detail.staffDetailVos = this.detailData.travellerList;
    } else {
      this.detail = this.detailData;
    }
  },
  filters: {
    format_TimeMonthDay(val) {
      return val ? moment(val).format("MM月DD日") : ""
    },
    carPolicyTypeText(val) {
      let valText = '';
      switch (val) {
        case 1:
          valText = '出差用车';
          break;
        case 2:
          valText = '加班用车';
          break;
        case 3:
          valText = '接送服务';
          break;
        case 4:
          valText = '代叫用车';
          break;
        case 5:
          valText = '定点通勤';
          break;
        case 6:
          valText = '外勤用车';
          break;
      }
      return valText
    },
    format_applyType (val) {
      switch (Number(val)) {
        case 1:
          return '出差申请';
          break;
        case 2:
          return '超标申请';
          break;
        case 3:
          return '报销申请';
          break;
        case 4:
          return '改签申请';
          break;
        case 5:
          return '退订申请';
          break;
      }
    },
  },
  async mounted() {
      await this.$store.state.policyDefer.promise;
      this.subsidyControlRule = store.state.policy.subsidyControlRule;
  },
  activated() {},
  watch: {},
  computed: {}
};
