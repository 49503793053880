//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import orderDetail from "@/lib/data-service/haolv-default/consumer_air_ticket_orderDetail";
import FlightClass from "../flightClass";
import fligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
import fligthLuggage from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthLuggage";
import cancelOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";

export default {
  name: "FlightDetail",
  data() {
    return {
      loading: false,
      detail: {}
    };
  },
  components: { FlightClass },
  methods: {
    async getOrderDetail() {
      let orderNo = this.$route.query.orderNo || "";
      this.loading = true
      let res = await orderDetail({orderNo})
      this.loading = false
      this.detail = res.datas || {};
    },
    goPay() {
      if (this.detail.evectionType === '个人预定') {
        this.$router.push({
          name: "admin-travel-payment",
          query: {
            orderNo: this.detail.orderNo,
            orderType: "1"
          }
        });
      } else {
        this.$router.push({
          name: 'admin-pay',
          query: {
            orderNos: this.detail.orderNo,
            orderType: "1"
          }
        });
      }
    },
    getFligthLuggage(flightNo) {
      fligthLuggage({ flightNo }).then(res => {
        console.log(res);
      });
    },
    getFligthRules() {
      let orderNo = this.detail.orderNo;
      fligthRules({ orderNo }).then(res => {
        console.log(res);
      });
    },

    cancel() {
      this.$confirm("确定要取消订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let data = {
            orderNo: this.detail.orderNo
          };
          cancelOrder(data).then(res => {
            this.$router.go(-1);
          });
        })
        .catch(() => {});
    }
  },
  created() {

  },
  async activated() {
    await this.getOrderDetail();
    // this.getFligthLuggage()
  },
  watch: {},
  computed: {}
};
