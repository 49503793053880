import Q from 'q';
import moment from 'moment';
import { Loading } from 'element-ui';
import ApproveTimeLine from "../.././approveTimeLine";
import ApplyDetail from "../.././applyDetail";
import ApplyDetailTrain from "../../applyDetailTrain/applyDetailTrain.vue";
import DialogBox from "../.././dialogBox";
import aduitEvection from "@/lib/data-service/haolv-default/consumer_apply_aduitEvection";
import { ChangeDateFormat } from "@/lib/utils/common.js";
import TicketChangeDetail from "../.././ticketChangeDetail";
import TicketRefundDetail from "../.././ticketRefundDetail";
import ExceedInfo from "../.././exceedInfo";
import FlightDetail from "@/page/admin/myOrder/component/flightDetail/index.vue";
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.5/index.vue';
import ApprovalProcessRejection from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/approval-process-rejection/1.0.0/index.vue';
// 【576】查看预算
import consumer_budget_getBudgetInfo from "@/lib/data-service/haolv-default/consumer_budget_getBudgetInfo";
// 【576】筛选预算
import consumer_budget_getBudgetOrgList from "@/lib/data-service/haolv-default/consumer_budget_getBudgetOrgList";
// 【576】获取预算险类种类
import consumer_budget_getBudgetInsurance from "@/lib/data-service/haolv-default/consumer_budget_getBudgetInsurance";
// 【576】获取预算项目种类
import consumer_budget_getBudgetPro from "@/lib/data-service/haolv-default/consumer_budget_getBudgetPro";
// 【576】筛选预算信息
import consumer_budget_getBudgetInfoByCondition from "@/lib/data-service/haolv-default/consumer_budget_getBudgetInfoByCondition";
// 【576】获取预算科目种类
import consumer_budget_getBudgetItem from "@/lib/data-service/haolv-default/consumer_budget_getBudgetItem";
// 【576】获取员工按钮类型的菜单
import consumer_web_staff_getButtonMenu from "@/lib/data-service/haolv-default/consumer_web_staff_getButtonMenu";



import ApprovalProcessApprovalProcess from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/approval-process-approval-process/1.0.0/index.vue'
// 新审批流程组件
import WorkflowProcessLine from "@/component/workflow-process-line/index.vue";
import WorkflowProcessLineForArray from "@/component/workflow-process-line-for-array/index.vue";
import {approveFlow_matching, formatStartNodeShow} from "@/lib/data-service/haolv-default/design";

// 新审批流操作
import AgreeModal from './../../process/AgreeModal';
import CommentModal from './../../process/CommentModal';
import DelegateModal from './../../process/DelegateModal';
import AssigneeModal from './../../process/AssigneeModal';
import ResolveModal from './../../process/ResolveModal';
import RefuseModal from './../../process/RefuseModal';
import RollbackModal from './../../process/RollbackModal';
import AddMultiModal from './../../process/AddMultiModal';
import QueryMultiUserModal from './../../process/QueryMultiUserModal';
import RevokeModal from './../../process/RevokeModal'
import DeleteMultiModal from './../../process/DeleteMultiModal';
export default {
  name: "LookTravel",
  data() {
    return {
      budgetDetailSwitch: false,
      fillRegionSwitch: true,
      polar: null,
      budgetStartDate: '',
      budgetProList: [],
      budgetOrgList: [],
      budgetItem: [],
      budgetInsuranceList: [],
      budgetOrg: '',
      budgetItemList: [
        {code: 1,name: '管理'},
        {code: 2,name: '理赔'},
        {code: 3,name: '销售'},
      ],
      budgetInfoByCondition: null,
      budgetLoading: false,
      formNew: {
        budgetStartDate: '',
        budgetOrg: '',
        budgetStartMonth: '',
        budgetEndMonth: '',
        budgetInsuranceCode: '',
        budgetInsuranceName: '',
        budgetItemCode: '',
        budgetItemName: '',
        budgetOrgCode: '',
        budgetOrgName: '',
        budgetProCode: '',
        budgetProName: '',
        budgetInfoByCondition: '',
      },
      form: {
        budgetStartMonth: '',   // 预算期间月份开始时间
        budgetEndMonth: '',    // 预算期间月份结束时间
        budgetInsuranceCode: '',   // 预算险类
        budgetInsuranceName: '',
        budgetItemCode: '',   // 科目
        budgetItemName: '',
        budgetOrgCode: '',   // 预算组织
        budgetOrgName: '',
        budgetProCode: '',   // 项目
        budgetProName: '',
        currentPage: 1,
        pageSize: 1,
      },
      dialogBudget: false,
      toPassApplySwitch: true,
      loading: false,
      loadingInstance: null,
      dialogShow: false,
      dialogChangeShow: false,
      activitiesNum: 0,
      evectionNo: this.$route.query.evectionNo || '',
      activities: [],
      staffList: [], // 有审批流的审核人全集
      staffIdList: [],
      staffRadio: 1,

      modalConfig: {
        agreeVisible: false,
        commentVisible: false,
        delegateVisible: false,
        assigneeVisible: false,
        resolveVisible: false,
        refuseVisible: false,
        rollbackVisible: false,
        addMultiVisible: false,
        queryMultiUserVisible: false,
        revokeVisible: false,
        deleteMultiVisible: false
      },
      // workflowList: [],
      pickerOptions:{
        disabledDate: () => false,
        onPick: ({ maxDate, minDate }) => {
          if (minDate && !maxDate) {
            const oneYear = 365 * 24 * 60 * 60 * 1000;
            this.pickerOptions.disabledDate = time => {
              return time.getTime() < minDate.getTime() || time.getTime() > minDate.getTime() + oneYear;
            };
          } else {
            this.pickerOptions.disabledDate = () => false;
          }
        },
      },
    };
  },
  components: {
    ApproveTimeLine,
    DialogBox,
    ApplyDetail,
    ApplyDetailTrain,
    TicketChangeDetail,
    TicketRefundDetail,
    ExceedInfo,
    FlightDetail,
    TravelerMultipleSelector,
    ApprovalProcessRejection,
    ApprovalProcessApprovalProcess,
    WorkflowProcessLine,
    WorkflowProcessLineForArray,

    CommentModal,
    DelegateModal,
    AssigneeModal,
    ResolveModal,
    RefuseModal,
    RollbackModal,
    AddMultiModal,
    QueryMultiUserModal,
    RevokeModal,
    DeleteMultiModal,
    AgreeModal
  },
  props: {
    type: String,
    detailData: Object,
    getDetail: Function,
    opaList: {
      // 新审批流操作权限
      type: Array,
      default: ()=>[],
    },
    processInfo: {
      // 新审批流操作表单
      type: Object,
      default: ()=>{},
    },
  },
  methods: {
    changeBudgetOrg(value) {
      let funBox = (dataList,code) => {
        let box = null;
        dataList.forEach((item,index) => {
          if (item.value == code) {
            box = item;
            if (item.children) {
              box = item.children;
            }
          }
        });
        return box;
      };
      let dataBox = this.budgetOrgList;
      value.forEach((item,index) => {
        dataBox = funBox(dataBox,item);
        if (dataBox.label) {
          this.form.budgetOrgCode = '';
          this.form.budgetOrgName = dataBox.label;
        }
      });
    },
    queryClick() {
      if (!this.budgetStartDate) {
        this.$message.error('请选择预算区间');
        return;
      }

      this.formNew.budgetStartDate = this.budgetStartDate;
      this.formNew.budgetOrg = this.budgetOrg;
      this.formNew.budgetStartMonth = this.form.budgetStartMonth;
      this.formNew.budgetEndMonth = this.form.budgetEndMonth;
      this.formNew.budgetInsuranceCode = this.form.budgetInsuranceCode;
      this.formNew.budgetInsuranceName = this.form.budgetInsuranceName;
      this.formNew.budgetItemCode = this.form.budgetItemCode;
      this.formNew.budgetItemName = this.form.budgetItemName;
      this.formNew.budgetOrgCode = this.form.budgetOrgCode;
      this.formNew.budgetOrgName = this.form.budgetOrgName;
      this.formNew.budgetProCode = this.form.budgetProCode;
      this.formNew.budgetProName = this.form.budgetProName;
      this.formNew.budgetInfoByCondition = this.budgetInfoByCondition;

      this.form.budgetStartMonth = this.budgetStartDate[0];
      this.form.budgetEndMonth = this.budgetStartDate[1];
      this.budgetLoading = true;
      consumer_budget_getBudgetInfoByCondition(this.form).then(res => {
        this.budgetInfoByCondition = res.datas;
        let monthCodeBox = [];
        let monthDataBox = [];
        let monthMaxBox = [];
        res.datas.collectAmountList.forEach((CItem,CIndex) => {
          monthCodeBox.push(Number(CItem.budgetMonth.split('-')[1]));
          monthDataBox.push(CItem);
          monthMaxBox.push(Number(CItem.usedSettlingAmount) + Number(CItem.usedSettledAmount) + Number(CItem.occupyAmount) + Number(CItem.budgetAmount));
        });
        monthMaxBox.sort((a, b) => b - a);
        let blueData = [];   // 发生未结算数
        let orangeData = [];   // 发生已结算数
        let greenData = [];   // 占用数
        let redData = [];   // 预算数
        let xAxisData = [];
        let emphasisStyle = {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)'
          }
        };
        for (let i = 0; i < 12; i++) {
          xAxisData.push(`${i+1}月`);
          if (monthCodeBox.indexOf(i+1) != -1) {
            blueData.push(monthDataBox[monthCodeBox.indexOf(i+1)].usedSettlingAmount);
            orangeData.push(monthDataBox[monthCodeBox.indexOf(i+1)].usedSettledAmount);
            greenData.push(monthDataBox[monthCodeBox.indexOf(i+1)].occupyAmount);
            redData.push(monthDataBox[monthCodeBox.indexOf(i+1)].budgetAmount);
          } else {
            blueData.push(0);
            orangeData.push(0);
            greenData.push(0);
            redData.push(0);
          }
        }
        let blueDataMax = [];   // 发生未结算数--调整后数据
        let orangeDataMax = [];   // 发生已结算数--调整后数据
        let greenDataMax = [];   // 占用数--调整后数据
        let redDataMax = [];   // 预算数--调整后数据

        let adjustmentFunction = function (dataList) {
          let box = [];
          dataList.forEach((item,index) => {
            if (item / monthMaxBox[0] * 100 < 5 && item != 0) {
              if (item > 0) {
                box[index] = monthMaxBox[0] * 0.05;
              } else {
                box[index] = Number(`-${monthMaxBox[0] * 0.05}`);
              }
            } else {
              box[index] = item;
            }
          });
          return box;
        };

        blueDataMax = adjustmentFunction(blueData);
        orangeDataMax = adjustmentFunction(orangeData);
        greenDataMax = adjustmentFunction(greenData);
        redDataMax = adjustmentFunction(redData);

        let polar = {
          title: {
            text: ''
          },
          color: ['#74a0fa','#f7a945','#73deb4','#d9001b'],
          tooltip: {
            trigger: 'item',
            axisPointer: {
              type: 'shadow'
            },
            formatter: (params) => {
              let box = params.componentIndex == 0 ? blueData : params.componentIndex == 1 ? orangeData : params.componentIndex == 2 ? greenData : redData;
              let d = `${params.seriesName}<br>`;
              let item = [];
              item.push(`${box[params.dataIndex]}<br>`);
              return d + item.join('');
            }
          },
          grid: {
            bottom: 100,
          },
          xAxis: {
            type: 'category',
            data: xAxisData,
            name: '月份',
            axisLine: { onZero: true },
            splitLine: { show: false },
            splitArea: { show: false },
          },
          yAxis: {},
          series: [
            {
              name: '发生未结算数',
              type: 'bar',
              stack: 'one',
              emphasis: emphasisStyle,
              data: blueDataMax
            },
            {
              name: '发生已结算数',
              type: 'bar',
              stack: 'one',
              emphasis: emphasisStyle,
              data: orangeDataMax
            },
            {
              name: '占用数',
              type: 'bar',
              stack: 'one',
              emphasis: emphasisStyle,
              data: greenDataMax
            },
            {
              name: '预算数',
              type: 'bar',
              stack: 'one',
              emphasis: emphasisStyle,
              data: redDataMax
            },
          ],
        };
        this.polar = polar;
        this.$nextTick(() => {
          this.$refs.chart.resize({
            width: 750,
            height: 400
          });
        })
      }).finally(() => {
        this.budgetLoading = false;
      })
    },
    async showDialogBudget() {
      this.dialogBudget = true;
      this.budgetStartDate = this.formNew.budgetStartDate;
      this.budgetOrg = this.formNew.budgetOrg;
      this.form.budgetStartMonth = this.formNew.budgetStartMonth;
      this.form.budgetEndMonth = this.formNew.budgetEndMonth;
      this.form.budgetInsuranceCode = this.formNew.budgetInsuranceCode;
      this.form.budgetInsuranceName = this.formNew.budgetInsuranceName;
      this.form.budgetItemCode = this.formNew.budgetItemCode;
      this.form.budgetItemName = this.formNew.budgetItemName;
      this.form.budgetOrgCode = this.formNew.budgetOrgCode;
      this.form.budgetOrgName = this.formNew.budgetOrgName;
      this.form.budgetProCode = this.formNew.budgetProCode;
      this.form.budgetProName = this.formNew.budgetProName;
      this.budgetInfoByCondition = this.formNew.budgetInfoByCondition;
      this.budgetStartDate = this.formNew.budgetStartDate;
      let detail = {};
      if ([3,5,2,4].indexOf(this.detailData.type) != -1 && this.detailData.type) {
        detail = this.$refs.applyDetailTrain.$data.detail;
      } else {
        detail = this.$refs.applyDetail.$data.detail;
      }
      if (this.polar == null) {
        this.budgetLoading = true;
        consumer_budget_getBudgetInfo({evectionNo: detail.evection.evectionNo}).then(async (res) => {
          this.budgetInfoByCondition = res.datas;
          // 预算期间
          this.budgetStartDate = [res.datas.budgetStartMonth,res.datas.budgetEndMonth];
          // 条形图
          let monthCodeBox = [];
          let monthDataBox = [];
          let monthMaxBox = [];
          res.datas.collectAmountList.forEach((CItem,CIndex) => {
            monthCodeBox.push(Number(CItem.budgetMonth.split('-')[1]));
            monthDataBox.push(CItem);
            monthMaxBox.push(Number(CItem.usedSettlingAmount) + Number(CItem.usedSettledAmount) + Number(CItem.occupyAmount) + Number(CItem.budgetAmount));
          });
          monthMaxBox.sort((a, b) => b - a);
          let blueData = [];   // 发生未结算数
          let orangeData = [];   // 发生已结算数
          let greenData = [];   // 占用数
          let redData = [];   // 预算数
          let xAxisData = [];
          let emphasisStyle = {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0,0,0,0.3)'
            }
          };
          for (let i = 0; i < 12; i++) {
            xAxisData.push(`${i+1}月`);
            if (monthCodeBox.indexOf(i+1) != -1) {
              blueData.push(monthDataBox[monthCodeBox.indexOf(i+1)].usedSettlingAmount);
              orangeData.push(monthDataBox[monthCodeBox.indexOf(i+1)].usedSettledAmount);
              greenData.push(monthDataBox[monthCodeBox.indexOf(i+1)].occupyAmount);
              redData.push(monthDataBox[monthCodeBox.indexOf(i+1)].budgetAmount);
            } else {
              blueData.push(0);
              orangeData.push(0);
              greenData.push(0);
              redData.push(0);
            }
          }
          let blueDataMax = [];   // 发生未结算数--调整后数据
          let orangeDataMax = [];   // 发生已结算数--调整后数据
          let greenDataMax = [];   // 占用数--调整后数据
          let redDataMax = [];   // 预算数--调整后数据

          let adjustmentFunction = function (dataList) {
            let box = [];
            dataList.forEach((item,index) => {
              if (item / monthMaxBox[0] * 100 < 5 && item > 0) {
                box[index] = monthMaxBox[0] * 0.05;
              } else {
                box[index] = item;
              }
            });
            return box;
          };

          blueDataMax = adjustmentFunction(blueData);
          orangeDataMax = adjustmentFunction(orangeData);
          greenDataMax = adjustmentFunction(greenData);
          redDataMax = adjustmentFunction(redData);

          let polar = {
            title: {
              text: ''
            },
            color: ['#74a0fa','#f7a945','#73deb4','#d9001b'],
            tooltip: {
              trigger: 'item',
              axisPointer: {
                type: 'shadow'
              },
              formatter: (params) => {
                let box = params.componentIndex == 0 ? blueData : params.componentIndex == 1 ? orangeData : params.componentIndex == 2 ? greenData : redData;
                let d = `${params.seriesName}<br>`;
                let item = [];
                item.push(`${box[params.dataIndex]}<br>`);
                return d + item.join('');
              }
            },
            grid: {
              bottom: 100,
            },
            xAxis: {
              type: 'category',
              data: xAxisData,
              name: '月份',
              axisLine: { onZero: true },
              splitLine: { show: false },
              splitArea: { show: false },
            },
            yAxis: {},
            series: [
              {
                name: '发生未结算数',
                type: 'bar',
                stack: 'one',
                emphasis: emphasisStyle,
                data: blueDataMax
              },
              {
                name: '发生已结算数',
                type: 'bar',
                stack: 'one',
                emphasis: emphasisStyle,
                data: orangeDataMax
              },
              {
                name: '占用数',
                type: 'bar',
                stack: 'one',
                emphasis: emphasisStyle,
                data: greenDataMax
              },
              {
                name: '预算数',
                type: 'bar',
                stack: 'one',
                emphasis: emphasisStyle,
                data: redDataMax
              },
            ],
          };
          this.polar = polar;
          this.$nextTick(() => {
            this.$refs.chart.resize({
              width: 750,
              height: 400
            });
          });
          // 条形图--end

          await this.$store.state.userInfoDefer.promise;
          let budgetFunction = (list) => {
            let box = [];
            if (list) {
              list.forEach((item,index) => {
                box.push({value: item.budgetOrgId,label: item.budgetOrgName,});
                if (item.childrenList && item.childrenList.length != 0) {
                  box[index].children = [];
                  box[index].children = budgetFunction(item.childrenList);
                }
              });
            }
            return box;
          };
          let budgetOrgBox = [];
          let budgetOrgFunction = (list,boxTwo) => {
            let box = boxTwo ? [...boxTwo] : [];
            for (let i = 0; i < list.length; i++) {
              box.push(list[i].value);
              if (list[i].children && list[i].children.length != 0) {
                budgetOrgFunction(list[i].children,box)
              } else {
                if (list[i].label == this.budgetInfoByCondition.budgetOrgName) {
                  budgetOrgBox = box;
                  return;
                }
                budgetOrgBox.push(box);
                box = [...boxTwo];
              }
            }
          };
          this.budgetOrgList = [];
          consumer_budget_getBudgetOrgList({companyId: this.$store.state.userInfo.companyId}).then(res => {
            res.datas.departmentListResults.forEach((item,index) => {
              let dataBox = {
                value: item.budgetOrgId,
                label: item.budgetOrgName,
                children: [],
              };
              dataBox.children = budgetFunction(item.childrenList);
              this.budgetOrgList.push(dataBox);
            });
            budgetOrgFunction(this.budgetOrgList);
            this.budgetOrg = budgetOrgBox;
            this.changeBudgetOrg(this.budgetOrg);
            this.formNew.budgetOrg = this.budgetOrg;
          });
          this.form.budgetItemName = this.budgetInfoByCondition.budgetItemName;
          this.form.budgetInsuranceName = this.budgetInfoByCondition.budgetInsuranceName;
          this.form.budgetProName = this.budgetInfoByCondition.budgetProName;
        }).finally(() => {
          this.budgetLoading = false;
          this.formNew.budgetStartDate = this.budgetStartDate;
          this.formNew.budgetStartMonth = this.form.budgetStartMonth;
          this.formNew.budgetEndMonth = this.form.budgetEndMonth;
          this.formNew.budgetInsuranceCode = this.form.budgetInsuranceCode;
          this.formNew.budgetInsuranceName = this.form.budgetInsuranceName;
          this.formNew.budgetItemCode = this.form.budgetItemCode;
          this.formNew.budgetItemName = this.form.budgetItemName;
          this.formNew.budgetOrgCode = this.form.budgetOrgCode;
          this.formNew.budgetOrgName = this.form.budgetOrgName;
          this.formNew.budgetProCode = this.form.budgetProCode;
          this.formNew.budgetProName = this.form.budgetProName;
          this.formNew.budgetInfoByCondition = this.budgetInfoByCondition;
        });
        consumer_budget_getBudgetInsurance().then(res => {
          this.budgetInsuranceList = res.datas;
        });
        consumer_budget_getBudgetPro().then(res => {
          this.budgetProList = res.datas;
        });
        consumer_budget_getBudgetItem().then(res => {
          this.budgetItem = res.datas;
        });
      } else {

      }
    },
    toPassApply() {
      this.loading = true;
      const approveType = Number(this.$route.query.approveType);
      const params = {
        approveType: approveType, // approveType 审批类型：1.出差申请，2.超标申请，3.报销申请，4.改签，5.退票
        applyNo: this.detailData.approveApply.applyNo, // 审批单号
        approveFlowId: this.detailData.approveApply.approveFlowId, // 审批流程流水号
        isHasFlow: this.detailData.isHasFlow, // 是否有流程
        isLastNode: this.detailData.isLastNode, // 是否流程最后一个
        flightDetail: this.detailData.flightDetail || [], // 机票详情
        hotelDetail: this.detailData.hotelDetail || [], //
        trainDetail: this.detailData.trainDetail || [],
      };
      if (approveType == 4 || approveType == 8) {
        // 机票详情
        params.flightDetail = this.detailData.changeDetailResponse ? this.detailData.changeDetailResponse.changeDetail : null;
        // 火车票详情
        params.trainDetail = this.detailData.trainsOrderChangeDetailResponse ? this.detailData.trainsOrderChangeDetailResponse : null;
      }
      if (approveType == 5 || approveType == 9) {
        // 机票详情
        params.flightDetail = this.detailData.refundOrdersDetailRespone ? this.detailData.refundOrdersDetailRespone.result : null;
        // 酒店详情
        params.hotelDetail = this.detailData.todHotelOrderInterimResponse ? this.detailData.todHotelOrderInterimResponse.orderInterimResult : null;
        // 火车票详情
        params.trainDetail = this.detailData.trainsOrderReturnDetailResponse ? this.detailData.trainsOrderReturnDetailResponse : null;
      }
      this.$refs.aApprovalProcessApprovalProcess.run(params).then(result => {
        if (result.type === '001') {
          this.$router.push({ name: "admin-my-apply-manage-have-approve" });
        }
        this.loading = false;
      })
    },
    applyReject() {

      const approveType = Number(this.$route.query.approveType);
      let params = {
        applyNo: this.detailData.approveApply.applyNo,
        approveType
      };
      this.$refs.aApprovalProcessRejection.run(params)
        .then(res => {
          if (res.resultType) {
            //拒绝成功
            this.$router.push({
              name: 'admin-my-apply-manage-have-approve',
            })
          } else{
          //拒绝失败

          }
        })
        .catch(rej => {
          // this.toPassApplySwitch = false;
        });
    },
    back() {
      this.$router.go(-1)
    },
    hideFillRegion() {
        this.fillRegionSwitch = false;
    },
    getFlow() {
      //formatStartNodeShow({"processInstanceId": "1780042357087338496", "userId": 2024, "deptId": 205}).then(res=>{
      formatStartNodeShow({
        processInstanceId: this.$route.query.applyNo,
        userId: this.$store.state.userInfo.id,
        deptId: this.$store.state.userInfo.deptId,
        budgetAmount: this.detailData.evection.budgetAmount || this.detailData.budgetAmount || 0,
      }).then(res=>{
        let list = res.result;
        let form = {
          line: list
        }
        this.activities = list;
        console.log(this.activities);
        // this.workflowList = list;
        console.log(this.$refs.workflowProcessLineForArray);
        this.$refs.workflowProcessLineForArray.init(form);
      })
      /*approveFlow_matching({companyId: this.$store.state.userInfo.companyId, userId: this.$store.state.userInfo.id, groupId: 1}).then(res=>{
        let form = res.result;
        console.log(this);
        console.log('123', this.$refs.workflowProcessLine);
        this.$refs.workflowProcessLine.init(form);
      });*/
    },

    // 新审批
    newPass(val) {
      switch (val.key) {
        case 'pass':
          // 通过
          this.onAgree();
          break
        case 'refuse':
          // 拒绝
          this.onRefuse();
          break
        case 'reject':
          // 驳回
          this.onRollback();
          break
        case 'frontJoin':
          // 委派
          this.onDelegateTask();
          break
        case 'backJoin':
          // 转办
          this.onAssignee();
          break
        case 'addAssignee':
          // 加签
          this.onAddMulti();
          break
        case 'delAssignee':
          // 减签
            this.onDeleteMulti();
          break
      }
    },
    handleCommand(val) {
      console.log(val);
      this.opaList.forEach(value => {
        if (value.key === val) {
          this.newPass(value);
        }
      })
    },

    onAgree() {
      this.modalConfig.agreeVisible = true;
    },
    onDelegateTask() {
      this.modalConfig.delegateVisible = true;
    },
    onResolveTask() {
      this.modalConfig.resolveVisible = true
    },
    onRefuse() {
      this.modalConfig.refuseVisible = true
    },
    onRevoke() {
      this.modalConfig.revokeVisible = true;
    },

    onAssignee() {
      this.modalConfig.assigneeVisible = true
    },
    onRollback() {
      this.modalConfig.rollbackVisible = true;
    },
    onAddMulti() {
      this.modalConfig.addMultiVisible = true;
    },
    onQueryMultiUsersInfo() {
      this.modalConfig.queryMultiUserVisible = true;
    },
    onDeleteMulti() {
      this.modalConfig.deleteMultiVisible = true;
    },
    // 添加评论
    onComments() {
      this.modalConfig.commentVisible = true;
    },

    _success() {
      this.back();
    },
  },
  created() {},
  mounted() {},
  activated() {},
  watch: {
    opaList(newVal) {
      if (this.$store.state.workflow === '1') {
        this.activitiesNum = newVal.length - 1;
        this.activities = [];
      }
    },
    detailData(val) {
      if (this.$store.state.workflow === '2') {
        this.activitiesNum = 0;
        if (val.recordList && val.recordList.length) {
          this.activities = val.recordList.map((item) => {
            let statu = item.approveStatus; //未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (statu === 1) this.activitiesNum++;
            let color = statu === 2 ? "#58CA18" : statu === 2 ? "#ff8c4d" : "";
            let icon = statu === 2 ? "el-icon-check" : "el-icon-more";
            let statuText = '';
            switch (statu) {
              case 1:
                statuText = "待审核"
                break
              case 2:
                statuText = "审核通过"
                break
              case 3:
                statuText = "驳回"
                break
              case 4:
                statuText = "已失效"
                break
              default:
                statuText = "未开始"
            }
            let showTime = '';
            if (item.gmtModified) {
              showTime = moment(item.gmtModified).format('YYYY-MM-DD HH:mm:ss')
            }
            let content = '';
            if (item.approverUserName) {
              content+= `${item.approverUserName}  `;
            }
            content+= `${
                item.deptName ? item.deptName : ""
            }`
            return {
              content,
              timestamp: statuText + ' ' + showTime,
              color: color,
              icon: icon,
              name: "",
            };
          });

          const approveType = Number(this.$route.query.approveType);
          console.log(val);
          if (approveType === 2) {
            this.activities.unshift({
              name: `${val.evection.initialName || ''} ${val.evection.feeAffiliationName || ''}`,
              timestamp: `发起时间: ${ChangeDateFormat(
                  val.recordList[0].gmtCreate
              )}`,
              color: "#58CA18 ",
              icon: "el-icon-check",
            });
          } else {
            this.activities.unshift({
              content: "",
              name: `${val.evection.initialName || ''} ${val.evection.feeAffiliationName || ''}`,
              timestamp: `发起时间:${ChangeDateFormat(
                  val.recordList[0].gmtCreate
              )}`,
              color: "#58CA18 ",
              icon: "el-icon-check",
            });
          }
        }
      }
    },
    loading (val) {
      if (val) {
        this.loadingInstance = Loading.service({target: 'body', text: '请求中...'});
      } else {
        this.loadingInstance.close();
      }
    },
  },
  computed: {
    showMoreOpa() {
      let moreBtnList = [];
      let moreBtn = ['reject', 'frontJoin', 'backJoin', 'addAssignee', 'delAssignee'];
      this.opaList.forEach(value => {
        if (moreBtn.indexOf(value.key) > -1 && value.checked) {
          moreBtnList.push(value);
        }
      })
      return moreBtnList;
    },
    currentProcessInfo: {
      get () {
        return this.processInfo
      },
      set (val) {
        this.$emit('update:processInfo', val)
      }
    }
  },
};
