import OrgPicker from "@/page/admin/approval-process/component/common/OrgPicker";
export default {
    data() {
        return {
            select: [],
            selectedNode: {},
            processUsers: {},
            formData: {},
            form2: {
                formId: "",
                formName: "",
                // logo: {},
                formItems: [],
                process: {},
                remark: "",
            },
            loginUser: {},
            conditionFormItem: new Set(),
            activities: [],
            cc: [],
        }
    },
    props: {},
    components: {OrgPicker},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        // 被侦听的变量count
        formData: {
            // 对对象进行深度监听
            handler(nv) {
                this.startProcess(nv, (this.cc = []));
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {},
    filters: {},
    methods: {
        init(form) {
            // form.logo = JSON.parse(form.logo);
            // form.settings = JSON.parse(form.settings);
            form.formItems = JSON.parse(form.formItems);
            form.process = JSON.parse(form.process);
            console.log(form.process);
            this.form2 = form;
            //构建表单及校验规则
            this.$store.state.design = form;
            this.startProcess(form.process, this.formData);
        },

        startProcess() {
            this.cc = [];
            this.getProcess(this.form2.process, this.activities, this.cc);

            this.cc.push({
                title: "结束",
                name: "END",
                icon: "el-icon-success",
                isEdit: false,
            });
            console.log('jieshu');
            console.log(this.cc);
        },

        getProcess(process, data, cc) {
            console.log('1', process, data, cc);
            if (null !== process && undefined !== process) {
                if ("ROOT" === process.type) {
                    //发起人节点
                    this.getRootNode(cc, process);
                } else if ("APPROVAL" === process.type) {
                    //审批节点
                    this.getApprovalNode(cc, process);
                } else if ("CC" === process.type) {
                    this.getCcNode(cc, process);
                } else if ("CONDITIONS" === process.type) {
                    //判断是否符合条件符合走条件分支,否则继续递归子分支
                    if (null !== process.branchs && undefined !== process.branchs) {
                        this.getConditionNode(cc, process);
                    }
                } else if ("CONCURRENTS" === process.type) {
                    this.getConcurrentNode(cc, process);
                }
                if (null !== process.children && undefined !== process.children) {
                    this.getProcess(process.children, data, cc);
                }
            }
        },
        //封装开始节点
        getRootNode(cc, process) {
            cc.push({
                id: process.id,
                title: process.name,
                name: "发起人",
                icon: "el-icon-user-solid",
                isEdit: false,
                users: [this.loginUser],
            });
        },

        //封装审批节点
        getApprovalNode(cc, process) {
            var data = {
                id: process.id,
                title: process.name,
                name: "审批人",
                icon: "el-icon-s-check",
                isEdit: false,
                multiple: false,
                mode: process.props.mode,
                users: [],
                desc: "",
            };
            //判断审批人类型
            switch (process.props.assignedType) {
                case "ASSIGN_USER":
                    data.users = this.$deepCopy(process.props.assignedUser);
                    data.desc = "指定审批人";
                    break;
                case "ASSIGN_LEADER":
                    data.desc = "指定部门的领导";
                    break;
                case "SELF":
                    data.users = [this.loginUser];
                    data.desc = "发起人自己审批";
                    break;
                case "SELF_SELECT":
                    data.isEdit = true;
                    data.multiple = process.props.selfSelect.multiple || false;
                    data.desc = "自选审批人";
                    break;
                case "LEADER_TOP":
                    data.desc = "连续多级主管审批";
                    break;
                case "LEADER":
                    data.desc =
                        1 === process.props.leader.level
                            ? "直接主管审批"
                            : "第".concat(process.props.leader.level, "级主管审批");
                    break;
                case "ROLE":
                    data.desc = "由角色[".concat(
                        (process.props.role || []).map(function (e) {
                            return e.name;
                        }),
                        "]审批"
                    );
                    break;
                case "REFUSE":
                    data.desc = "流程此处将被自动驳回";
                    break;
            }
            cc.push(data);
        },
        getCcNode(cc, process) {
            var data = {
                id: process.id,
                title: process.name,
                icon: "el-icon-s-promotion",
                name: "抄送人",
                isEdit: process.props.shouldAdd,
                type: "org",
                multiple: true,
                desc: process.props.shouldAdd ? "可添加抄送人" : "",
                users: this.$deepCopy(process.props.assignedUser),
            };
            cc.push(data);
        },
        getConditionNode(cc, process) {
            for (var r = null, s = 0; s < process.branchs.length; s++) {
                for (
                    var a = process.branchs[s], n = false, o = 0, i = 0;
                    i < a.props.groups.length;
                    i++
                ) {
                    if (
                        ((n = this.getConditionResultByGroup(a.props.groups[i])),
                        "OR" === a.props.groupsType && n)
                    ) {
                        r = a;
                        break;
                    }
                    "AND" === a.props.groupsType && n && o++;
                }

                if (r) {
                    break;
                }
                if (o === a.props.groups.length) {
                    r = a;
                    break;
                }
            }
            var d = [];
            console.log(
                "符合分支条件,继续执行递归,获取符合条件下节点下的子节点!" +
                JSON.stringify(r)
            );
            r
                ? this.getProcess(r, d, cc)
                : console.log(
                    "条件节点 "
                        .concat(process.id, " => ")
                        .concat(process.name, " 均不满足，无法继续"),
                    process
                );
        },
        getConcurrentNode(cc, process) {
            var data = {
                id: process.id,
                title: process.name,
                name: "并行分支",
                icon: "el-icon-s-operation",
                isEdit: false,
                active: process.branchs[0].id,
                options: [],
                desc: "切换分支可显示对应执行流程",
                branchs: {},
            };
            cc.push(data);
            process.branchs.forEach((b) => {
                data.options.push({ id: b.id, title: b.name });
                this.$set(data.branchs, b.id, []);
                var d = [];
                this.getProcess(b.children, d, data.branchs[b.id]);
            });
        },
        getConditionResultByGroup: function (e) {
            var t = this,
                r = !1,
                s = 0;
            e.conditions.forEach(function (e) {
                return t.conditionFormItem.add(e.id);
            });

            for (var a = 0; a < e.conditions.length; a++) {
                var n = e.conditions[a];
                switch (n.valueType) {
                    case "Number":
                        console.log("这是number类型判断");
                        r = this.numberCompare(n);
                        break;
                    case "String":
                        console.log("这是string类型判断");
                        break;
                    case "Date":
                        console.log("这是data类型判断");

                        break;
                    case "User":
                        console.log("这是user类型判断");

                        break;
                    case "Array":
                        console.log("这是array类型判断");

                        break;
                }
                if (r && "OR" === e.groupType) break;
                r && "AND" === e.groupType && s++;
            }
            return "AND" === e.groupType && (r = s === e.conditions.length), r;
        },
        numberCompare: function (e) {
            var t = this.formData[e.id];
            switch (e.compare) {
                case ">":
                    return t > parseFloat(e.value[0]);
                case "<":
                    return t < parseFloat(e.value[0]);
                case "=":
                    return t === parseFloat(e.value[0]);
                case ">=":
                    return t >= parseFloat(e.value[0]);
                case "<=":
                    return t <= parseFloat(e.value[0]);
                case "B":
                    return t > parseFloat(e.value[0]) && t < parseFloat(e.value[1]);
                case "AB":
                    return t >= parseFloat(e.value[0]) && t < parseFloat(e.value[1]);
                case "BA":
                    return t > parseFloat(e.value[0]) && t <= parseFloat(e.value[1]);
                case "ABA":
                    return t >= parseFloat(e.value[0]) && t <= parseFloat(e.value[1]);
                case "IN":
                    return e.value.indexOf(String(t)) > -1;
                default:
                    return !1;
            }
        },

        delUser(e, t) {
            e.splice(t, 1);
            this.processUsers = e;
        },
        addUser(e) {
            (this.selectedNode = e), this.$refs.orgPicker.show();
        },
        selected(e) {
            var t = this;
            t.processUsers[t.selectedNode.id] = [];

            e.forEach((user) => {
                var ddd = t.selectedNode.users.findIndex((t) => {
                    return t.id === user.id;
                });
                if (ddd === -1) {
                    t.selectedNode.users.push(user);
                    t.processUsers[t.selectedNode.id].push(user);
                    t.$set(e, "isEdit", true);
                }
            });
        },
    }
}