// consumer_role_roleAll
const __request = require(`./__request/__request_contentType_json`);
// 退票申请审批：http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-approve-apply-controller/aduitRefundUsingPOST
const consumer_apply_aduitRefund = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/apply/aduitRefund',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_apply_aduitRefund;